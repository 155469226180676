import React, { useEffect, useState } from 'react';
import './Navbar.css'
import { Button } from '@mui/material';
import Logo from '../../Assets/logo.png'
import { Link, useAsyncError } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate()

    // useEffect(() => {
    //     const navbarBox = document.getElementById("navbarBox");

    //     const sticky = "200";

    //     const handleScroll = () => {
    //         if (window.scrollY >= sticky) {
    //             navbarBox.classList.add("sticky");
    //         } else {
    //             navbarBox.classList.remove("sticky");
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
    const [frontPage, setFrontPage] = useState(1);
    const [openMenu, setOpenMenu] = useState(false);
    const [front, setFront] = useState(true);

    const showFront = () => {
        setFront(true)
        setOpenMenu(false)
        localStorage.setItem("frontPage", frontPage)
    }

    const hideFront = () => {
        setFront(false)
        setOpenMenu(false)
        
    }

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu);
    };

    const handleTeam = () => {
        navigate("/team")
        setOpenMenu(!openMenu);
        setFront(false)
    }

    const barStyles = {
        bar: {
            backgroundColor: openMenu ? 'rgb(247, 62, 62)' : 'black',
        },
        bar1: {
            transform: openMenu ? 'translate(0px,8px) rotate(-45deg)' : 'rotate(0deg)',
        },
        bar2: {
            opacity: openMenu ? '0%' : '100%',
        },
        bar3: {
            transform: openMenu ? 'translate(0px,-14px) rotate(45deg)' : 'rotate(0deg)',
        },
    };

    return (
        <div id='navbarBox' style={{ zIndex: '100', backgroundColor: 'white' }} >
            <div className='navbar'>
                <img src={Logo} alt="" id='navbarLogo' />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div id='navbarLinks' >
                        <div style={{ display: "flex", alignItems: 'center' }}>

                            <Link to="/" ><a href="#" onClick={showFront} >Home</a></Link>
                            {front && (
                                <>
                                    <a href="#aboutus">About</a>
                                    <a href="#box1">Gallery</a>
                                    <a href="#big-div">Testimonials</a>
                                    <a href="#contactus">Contact</a>
                                </>
                            )}
                            <Link to="/artists" ><a href="#" onClick={hideFront} >Artists</a></Link>
                            <div id='submenu'>
                                <a style={{ display: 'flex', alignItems: 'center' }} >Others <KeyboardArrowDownIcon style={{ fontWeight: '10', marginLeft: '2px' }}></KeyboardArrowDownIcon>  </a>
                                <ul id='submenuList' >
                                    <Link to="/team" >Team </Link>
                                    <li>Calender</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Button variant="contained" style={{ backgroundImage: 'linear-Gradient(to right, #af05d0, #c500bd, #d500ab, #df009a, #e6118b, #f2287c, #fa3d6e, #ff5262, #ff6f51, #ff8d41, #ffaa37, #fcc737)', padding: 'auto 30px' }} onClick={() => navigate('/booking')} >Book Now</Button>
                    <div className="menuButton" onClick={handleOpenMenu}>
                        <div id="bar1" className='bar' style={{ ...barStyles.bar, ...barStyles.bar1 }}></div>
                        <div id="bar2" className='bar' style={{ ...barStyles.bar, ...barStyles.bar2 }}></div>
                        <div id="bar3" className='bar' style={{ ...barStyles.bar, ...barStyles.bar3 }}></div>
                    </div>
                </div>
            </div >

            <div id="navbar2links" style={{ width: (openMenu) ? '100%' : '0%' }} >
                <Link to="/" ><a href="#" onClick={showFront} >Home</a></Link>
                {front && (
                    <>
                        <a href="#">About</a>
                        <a href="#">Gallery</a>
                        <a href="#">Testimonials</a>
                        <a href="#">Contact</a>
                    </>
                )}
                <Link to="/artists" ><a href="#" onClick={hideFront} >Artists</a></Link>
                <a onClick={handleTeam} >Team </a>
            </div >
        </div>
    )
}

export default Navbar;
