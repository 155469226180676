import React from 'react'
import styles from "./About.module.css"
import clsx from "clsx";
import Slider from "react-slick";
import BG_STARS from "./BG_STARS.svg";
import Ticket from '../../Assets/About/Ticket.png';
import City1 from '../../Assets/About/City1.png';
import City2 from '../../Assets/About/City2.png';
import Artists from '../../Assets/About/Artists.png';
import Shows from '../../Assets/About/Shows.png';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import InterpreterModeOutlinedIcon from '@mui/icons-material/InterpreterModeOutlined';
import { Grid } from '@mui/material';

const About = () => {

    return (
        <div id='aboutus'>

            <div style={{
                height: '100%',
                margin: '50px 0px'
            }}>
                <div className={clsx(styles.creativity)}>
                    <h1 className={styles.title}>
                        <span className={styles.gradient}>ABOUT </span> US <br />
                    </h1>
                    <p className={styles.desc} style={{letterSpacing:'2px', fontWeight:'400'}}>
                        Oriole Entertainment is the pioneer of the Tier 2 and Tier 3 city comedy shows. We have been dedicated to making people laugh since 2017 and plan to do it for the rest of our lives! We’re most popularly known as the house of Anubhav Singh Bassi and Harsh Gujral.
                        Our shows have made people chuckle in cities like Agra, Gurugram, Lucknow, Kanpur, Dehradun and many more!
                        Oriole Entertainment Pvt Ltd was founded by Ankur Bhargava.
                    </p>

                    <div className={styles.desktopcards}>
                        <div className={clsx(styles.card, styles.thanks)}>
                            <div className={styles.cardInside}>
                                <div className={styles.imgDiv}>
                                    <ReceiptOutlinedIcon className={styles.icons} style={{ color: 'indianred', transform: 'rotate(25deg)', margin: '15px', fontSize: '60px' }} ></ReceiptOutlinedIcon>

                                </div>
                                <h3 className={styles.cardTitle}>5,00,000</h3>
                                <p>
                                    Number of Tickets sold successfully to the customers.
                                </p>
                            </div>
                        </div>
                        <div className={clsx(styles.card, styles.CreatorStore)}>
                            <div className={styles.cardInside}>
                                <div className={styles.imgDiv}>
                                    <BusinessOutlinedIcon className={styles.icons} style={{ color: 'indianred', margin: '15px', fontSize: '60px' }} ></BusinessOutlinedIcon>
                                </div>
                                <h3 className={styles.cardTitle}>75</h3>
                                <p>
                                    Presenting shows organized in the number of cities on-site.
                                </p>
                            </div>
                        </div>
                        <div className={clsx(styles.card, styles.nftWithPerks)}>
                            <div className={styles.cardInside}>
                                <div className={styles.imgDiv}>
                                    <MicExternalOnOutlinedIcon className={styles.icons} style={{ color: 'indianred', margin: '15px', transform: 'rotate(25deg)', fontSize: '60px' }} ></MicExternalOnOutlinedIcon>
                                </div>
                                
                                <h3 className={styles.cardTitle}>65+</h3>
                                <p>
                                    Collaborated with over 65 artists in our endeavors.
                                </p>
                            </div>
                        </div>
                        <div className={clsx(styles.card, styles.membership)}>
                            <div className={styles.cardInside}>
                                <div className={styles.imgDiv}>
                                    {/* <img className={styles.cardImg} src={Shows} alt="" /> */}
                                    <InterpreterModeOutlinedIcon className={styles.icons} style={{ color: 'indianred', margin: '15px',fontSize: '60px' }}  ></InterpreterModeOutlinedIcon>
                                </div>
                                <h3 className={styles.cardTitle}>2500</h3>
                                <p>
                                    Executed events in multiple cities, showcasing diverse shows and entertainment experiences
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div >

            <div className={styles.youtubeBox} style={{ display: 'flex', justifyContent: 'center', marginTop: '15%', marginBottom:'7%' }}>
                <iframe className={styles.youtubeLink} style={{ width: '80%', height: '500px' }} src="https://www.youtube.com/embed/KaZI8ry86dU?si=ou8lnl0U42ZXOChC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default About
