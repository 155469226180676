import React from "react";
import "./Contactus.css";
import phoneicon from './icons/phone-icon.svg';
import mailicon from './icons/mail-icon.svg';
import locationicon from './icons/location-icon.svg';

const Contactus = () => {
  return (
    <div id="contactus">
      <div id='box1' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '20px 0px 20px 70px' }} >
        <h1 className="title">
          <span className="gradient">CONTACT </span> <br />
        </h1>
        <div id='box2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
          <p style={{ fontWeight: '100', color: 'grey' }} >Contact Us</p>
          <hr id='line' style={{ border: '.5px orange solid', width: '120px', marginLeft: '10px' }} />
        </div>
      </div>
      <div id="contactus-grid">
        <div id="grid-part1">
          <div id="our-address">
            <div><img src={phoneicon} alt="phone-icon" /></div>
            <p className="heading-text">Our Address</p>
            <p>B4/201, Safdarjung Enclave, Delhi, India-110029</p>
          </div>
          <div id="grid-part1a">
            <div id="email-us">
              <div><img src={mailicon} alt="mail-icon" /></div>
              <p className="heading-text">Email Us</p>
              <p className="main-text">team@orioleentertainment.com</p>
              <p className="main-text">orioleentertainment@gmail.com</p>
            </div>
            <div id="call-us">
              <div><img src={locationicon} alt="phone-icon" /></div>
              <p className="heading-text">Call Us</p>
              <p className="main-text">+91 078301 00001</p>
            </div>
          </div>
        </div>
        <div id="grid-part2">
          <div id="contact-details">
            <div className="input-line1"><input type="text" placeholder="Your Name" /><input type="text" placeholder="Your Email" /></div>
            <div><input type="text" placeholder="Subject" /></div>
            <div><textarea type="text" placeholder="Message" /></div>
            <button id="send-button">Send Message</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
