import React from 'react'
import { Grid } from '@mui/material';
import './Team.css';
import img1 from '../Assets/team/team-1.png';
import img2 from '../Assets/team/team-2.png';
import img3 from '../Assets/team/tech (2).png';
import img4 from '../Assets/team/tech.png';
import img5 from '../Assets/team/pratham.png';
import img6 from '../Assets/team/tech (5).png';
import img7 from '../Assets/team/tech (3).png';
import img8 from '../Assets/team/photo10.jpeg';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Team = () => {

    const data = [
        { photo: img3, name: "Archit Mittal", role: "Team Member" },
        { photo: img4, name: "Aishleen Singh", role: "Team Member" },
        { photo: img5, name: "Pratham Sharma", role: "Team Member" },
        { photo: img6, name: "Saksham Misra", role: "Team Member" },
        { photo: img7, name: "Vitesh Malhotra", role: "Team Member" },
        { photo: img8, name: "Naresh Kumar", role: "Manager" }
    ]

    return (
        <div className='TeamBox'>
            <div id='box1' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '85%', margin: '20px 0px' }} >
                <h1 className="title">
                    <span className="gradient">TEAM </span> <br />
                </h1>
                <div id='box2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                    <p style={{ fontWeight: '100', color: 'grey' }} >CHECK OUT OUR TEAM</p>
                    <hr id='line' style={{ border: '.5px orange solid', width: '120px', marginLeft: '10px' }} />
                </div>
            </div>

            <div className="TeamGallery1">
                <div className='team' >
                    <img src={img1} alt="" />
                    <div className="detail1">
                        <a href="#"><TwitterIcon id='links1'></TwitterIcon></a>
                        <a href="#"><InstagramIcon id='links1' ></InstagramIcon></a>
                        <a href="#"><FacebookIcon id='links1' ></FacebookIcon></a>
                        <a href="#"><LinkedInIcon id='links1' ></LinkedInIcon></a>
                    </div>
                    <div className="detail2">
                        <p>Ankhur Bhargava <br /> <i id='role1'> Director </i> </p>
                    </div>
                </div>
                <div className='team' >
                    <img src={img2} alt="" />
                    <div className="detail1">
                        <a href="#"><TwitterIcon id='links1'></TwitterIcon></a>
                        <a href="#"><InstagramIcon id='links1' ></InstagramIcon></a>
                        <a href="#"><FacebookIcon id='links1' ></FacebookIcon></a>
                        <a href="#"><LinkedInIcon id='links1' ></LinkedInIcon></a>
                    </div>
                    <div className="detail2">
                        <p>Nupur Ankur <br /> Bhargava <br /> <i id='role1'> Joint Director </i> </p>
                    </div>
                </div>
            </div>

            <div className="TeamGallery2">
                {data.map((object) => {
                    return <div className='team' >
                        <img src={object.photo} alt="" />
                        <div className="detail3">
                            <a href="#"><TwitterIcon id='links2'></TwitterIcon></a>
                            <a href="#"><InstagramIcon id='links2' ></InstagramIcon></a>
                            <a href="#"><FacebookIcon id='links2' ></FacebookIcon></a>
                            <a href="#"><LinkedInIcon id='links2' ></LinkedInIcon></a>
                        </div>
                        <div className="detail4">
                            <p>{object.name} <br /> <i id='role2'> {object.role} </i> </p>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Team;
