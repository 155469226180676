import React from 'react';
import './Gallery.css';
import { Grid } from '@mui/material';
import img1 from '../../Assets/Gallery/img1.JPG'
import img2 from '../../Assets/Gallery/img2.JPG'
import img3 from '../../Assets/Gallery/img3.jpeg'
import img4 from '../../Assets/Gallery/img4.jpeg'
import img5 from '../../Assets/Gallery/img5.jpeg'
import img6 from '../../Assets/Gallery/img6.jpeg'
import img7 from '../../Assets/Gallery/img7.jpeg'
import img8 from '../../Assets/Gallery/img8.jpeg'
import img9 from '../../Assets/Gallery/img9.jpeg'
import img10 from '../../Assets/Gallery/img10.png'

const Gallery = () => {

    return (
        <Grid className='galleryBox' >
            <div id='box1' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '20px 0px 20px 70px' }} >
                <h1 className="title">
                    <span className="gradient">GALLERY </span> <br />
                </h1>
                <div id='box2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                    <p style={{ fontWeight: '100', color: 'grey' }} >Check out our gallery</p>
                    <hr style={{ border: '.5px orange solid', width: '120px', marginLeft: '10px' }} />
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <img src={img5} style={{ width: "100%" }} />
                    <img src={img7} style={{ width: "100%" }} />
                    <img src={img9} style={{ width: "100%" }} />
                    <img src={img6} style={{ width: "100%" }} />
                </div>
                <div className="column">
                    <img src={img10} style={{ width: "65%" }} />
                    <img src={img3} style={{ width: "100%" }} />
                    <img src={img2} style={{ width: "100%" }} />
                </div>
                <div className="column">
                    <img src={img8} style={{ width: "100%" }} />
                    <img src={img4} style={{ width: "100%" }} />
                    <img src={img1} style={{ width: "100%" }} />
                </div>
            </div>
        </Grid>
    )
}

export default Gallery
