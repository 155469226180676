import React from "react";
import "./Reviewsection.css";
import person1 from "./images/riya.jpeg";
import person2 from "./images/satvik.png";
import person3 from "./images/vedic.png";
import leftquoteicon from "./icons/left-quotes-black.svg";
import rightquoteicon from "./icons/right-quotes-black.svg";

const ReviewSection = () => {
  return (
    <div>
      <div id="big-div">
        <div id='box1' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '20px 0px 20px 70px' }} >
          <h1 className="title">
            <span className="gradient">TESTIMONIALS </span> <br />
          </h1>
          <div id='box2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
            <p style={{ fontWeight: '100', color: 'grey' }} >Testimonials</p>
            <hr id='line' style={{ border: '.5px orange solid', width: '120px', marginLeft: '10px' }} />
          </div>
        </div>
        <br />
        <div className="testimonials-section">
          <div className="review review1">
            <div className="icon-image">
              <img src={person1} alt="riya-pic" />
            </div>
            <div>
              <p>
                <b>Riya Saxena</b>
              </p>
              <p className="person-type">Attendee</p>
              <p class="testimonial-card-stars">★★★★★</p>
              <p>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={leftquoteicon}
                  alt="quote-icon"
                />{" "}
                <i>
                  {" "}
                  Had a great laugh! Great shows to hang out with friends.{" "}
                </i>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={rightquoteicon}
                  alt="right-quotes"
                />
              </p>
            </div>
          </div>

          <div className="review">
            <div className="icon-image">
              <img src={person2} alt="satvik-pic" />
            </div>
            <div>
              <p>
                <b>Satvik Gupta</b>
              </p>
              <p className="person-type">Attendee</p>
              <p class="testimonial-card-stars">★★★★★</p>
              <p>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={leftquoteicon}
                  alt="quote-icon"
                />{" "}
                <i>
                  {" "}
                  Bassi is my favourite comedian and Oriole made my dream come
                  true by meeting him one day. Thank you so much!{" "}
                </i>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={rightquoteicon}
                  alt="right-quotes"
                />
              </p>
            </div>
          </div>

          <div className="review">
            <div className="icon-image">
              <img src={person3} alt="vedic-pic" />
            </div>
            <div>
              <p>
                <b>Vedic Patel</b>
              </p>
              <p className="person-type">Attendee</p>
              <p class="testimonial-card-stars">★★★★★</p>
              <p>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={leftquoteicon}
                  alt="quote-icon"
                />{" "}
                <i>
                  {" "}
                  Best management team and super funny shows organised. Love it
                  everytime I attend.{" "}
                </i>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={rightquoteicon}
                  alt="right-quotes"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
