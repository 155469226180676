import React from 'react';
import { useState, useEffect } from 'react';
import './Slider_1.css'
import Img1 from '../../Assets/slide/img1.jpg'
import Img2 from '../../Assets/slide/img2.jpg'
import Img3 from '../../Assets/slide/img3.png'
import { Button } from '@mui/material';

const Slider_1 = () => {
    const [slideIndex, setSlideIndex] = useState(1);

    const plusSlides = (n) => {
        showSlides(slideIndex + n);
    };

    const currentSlide = (n) => {
        showSlides(n);
    };

    const showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName('mySlides');
        let dots = document.getElementsByClassName('dot');

        if (n > slides.length) {
            setSlideIndex(1);
        } else if (n < 1) {
            setSlideIndex(slides.length);
        } else {
            setSlideIndex(n);
        }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }

        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
        }

        slides[slideIndex - 1].style.display = 'block';
        dots[slideIndex - 1].className += ' active';
    };

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex]);

    return (
        <div className="slideshow-container">
            <div className="mySlides fade1" id="myslides1" >
                <div className='mySlidesText fade2' >
                    <h1>India's Biggest<br />Live Stand Up Comedy<br />Producers</h1>
                    <p>From Tier-1 to Tier-3 cities, we organize & produce Stand-Up Comedy <br /> shows throughout the nation with various artists all through the year.</p>
                    <Button variant="contained" style={{ backgroundImage: 'linear-Gradient(to right, #af05d0, #c500bd, #d500ab, #df009a, #e6118b, #f2287c, #fa3d6e, #ff5262, #ff6f51, #ff8d41, #ffaa37, #fcc737)', padding: 'auto 30px' }}  >Read More</Button>
                </div>
            </div>

            <div className="mySlides fade1" id="myslides2" >
                <div className='mySlidesText fade2' >
                    <h1>India's Biggest<br />Live Stand Up Comedy<br />Producers</h1>
                    <p>From Tier-1 to Tier-3 cities, we organize & produce Stand-Up Comedy <br /> shows throughout the nation with various artists all through the year.</p>
                    <Button variant="contained" style={{ backgroundImage: 'linear-Gradient(to right, #af05d0, #c500bd, #d500ab, #df009a, #e6118b, #f2287c, #fa3d6e, #ff5262, #ff6f51, #ff8d41, #ffaa37, #fcc737)', padding: 'auto 30px' }}  >Read More</Button>
                </div>
            </div>

            <div className="mySlides fade1" id="myslides3" >
                <div className='mySlidesText fade2' >
                    <h1>India's Biggest<br />Live Stand Up Comedy<br />Producers</h1>
                    <p>From Tier-1 to Tier-3 cities, we organize & produce Stand-Up Comedy <br /> shows throughout the nation with various artists all through the year.</p>
                    <Button variant="contained" style={{ backgroundImage: 'linear-Gradient(to right, #af05d0, #c500bd, #d500ab, #df009a, #e6118b, #f2287c, #fa3d6e, #ff5262, #ff6f51, #ff8d41, #ffaa37, #fcc737)', padding: 'auto 30px' }}  >Read More</Button>
                </div>
            </div>

            <a className="prev" onClick={() => plusSlides(-1)}>
                ❮
            </a>
            <a className="next" onClick={() => plusSlides(1)}>
                ❯
            </a>

            <div style={{ textAlign: 'center', position: 'absolute', bottom: '5%', left: '47%' }}  >
                <span className="dot" onClick={() => currentSlide(1)}></span>
                <span className="dot" onClick={() => currentSlide(2)}></span>
                <span className="dot" onClick={() => currentSlide(3)}></span>
            </div>
        </div>
    );
}

export default Slider_1
