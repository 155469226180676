import React from 'react'
import { Grid } from '@mui/material';
import './Artists.css';
import img1 from '../Assets/portfolio/1.png'
import img2 from '../Assets/portfolio/2.png'
import img3 from '../Assets/portfolio/3.png'
import img4 from '../Assets/portfolio/4.png'
import img5 from '../Assets/portfolio/5.png'
import img6 from '../Assets/portfolio/7.png'
import img7 from '../Assets/portfolio/8.png'
import img8 from '../Assets/portfolio/9.png'
import img9 from '../Assets/portfolio/10.png'
import img10 from '../Assets/portfolio/11.png'
import img11 from '../Assets/portfolio/12.png'
import img12 from '../Assets/portfolio/13.png'
import img13 from '../Assets/portfolio/14.png'
import img14 from '../Assets/portfolio/15.png'
import img15 from '../Assets/portfolio/17.JPG'
import img16 from '../Assets/portfolio/18.png'

import LinkIcon from '@mui/icons-material/Link';

const Artists = () => {

    const artistData = [
        { image: img1, name: "Anubhav Singh Bassi", link: '#' },
        { image: img2, name: "Harsh Gujral", link: '#' },
        { image: img3, name: "Zakir Khan", link: '#' },
        { image: img4, name: "Abhishek Upamanyu", link: '#' },
        { image: img5, name: "Rahul Dua", link: '#' },
        { image: img6, name: "Gaurav Gupta", link: '#' },
        { image: img7, name: "Appurv Gupta (Guptaji)", link: '#' },
        { image: img8, name: "Nishant Tanwar", link: '#' },
        { image: img9, name: "Aakash Gupta", link: '#' },
        { image: img10, name: "Rajat Chauhan", link: '#' },
        { image: img11, name: "Atul Khatri", link: '#' },
        { image: img12, name: "Vijay Yadav", link: '#' },
        { image: img13, name: "Abish Mathew", link: '#' },
        { image: img14, name: "Prashasti Singh", link: '#' },
        { image: img16, name: "Manik Mahna", link: '#' },
        { image: img15, name: "Gaurav Kapoor", link: '#' },
    ]

    return (
        <Grid className='ArtistsBox'>
            <div id='box1' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '20px 0px 20px 70px' }} >
                <h1 className="title">
                    <span className="gradient">ARTISTS </span> <br />
                </h1>
                <div id='box2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                    <p style={{ fontWeight: '100', color: 'grey' }} >ARTISTS WE HAVE WORKED WITH</p>
                    <hr id='line' style={{ border: '.5px orange solid', width: '120px', marginLeft: '10px' }} />
                </div>
            </div>
            <div className="ArtistsGallery">
                {
                    artistData.map((artist, index) => {
                        return <div key={index} className='artist' id='artistOne' >
                            <img src={artist.image} alt="" />
                            <div className='artistDetails' >
                                <p>{artist.name} </p>
                                <LinkIcon id={artist.link}></LinkIcon>
                            </div>
                        </div>
                    })
                }
            </div>
        </Grid>
    )
}

export default Artists;
